import classNames from 'classnames';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import SessionService from '../../services/SessionService';

export interface IBottomNavbarProps {
}

export interface IBottomNavbarState {
}

export default class BottomNavbar extends React.Component<IBottomNavbarProps, IBottomNavbarState> {
  constructor(props: IBottomNavbarProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    //TODO: Si besoin rajouter une vérification que la personne ai bien l'autorisation d'accéder a la boutique sélectionner
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const firstRoutingElement = pathSegments[1];
    const isDigit = /^\d+$/.test(firstRoutingElement);

    return (
      <div className={classNames({"bottom-navbar row-between": true})} >
        <NavLink className="bottom-navbar-link" to={"/"+ firstRoutingElement +"/news"}>
          <i className="fas fa-newspaper"></i>
          <div className="d-none d-sm-block">Actualité</div>
        </NavLink>
        <NavLink className="bottom-navbar-link" to={"/"+ firstRoutingElement +"/products"}>
          <i className="fas fa-tags"></i>
          <div className="d-none d-sm-block">Carte</div>
        </NavLink>
        <NavLink className="bottom-navbar-link" to={"/"+ firstRoutingElement +"/charts"}>
          <i className="fas fa-chart-line"></i>
          <div className="d-none d-sm-block">Statistiques</div>
        </NavLink>
        <NavLink className="bottom-navbar-link" to={"/"+ firstRoutingElement +"/orders"}>
          <i className="fas fa-pizza"></i>
          <div className="d-none d-sm-block">Commandes</div>
        </NavLink>
        <NavLink className="bottom-navbar-link" to={"/"+ firstRoutingElement +"/rewards"}>
          <i className="fas fa-trophy"></i>
          <div className="d-none d-sm-block">Loterie</div>
        </NavLink>
        <Dropdown>
              <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLDivElement>) => (
                <div onClick={onClick} className="btn btn-light rounded-pill" ref={ref}>
                  <i  className="fas fa-ellipsis-h my-auto"></i>
                </div>
              ))}/>
              <Dropdown.Menu align="right" className="fade" style={{marginTop: "1rem"}} >
                {SessionService.user.shops.map((shop)=> 
                <Dropdown.Item as={NavLink} to={"/"+ shop.id +"/charts"} >
                <div>{shop.adresse}</div>
              </Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
      </div>
      
    );
  }
}
