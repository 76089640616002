
import React from 'react';
import Model from '../../models/Model';
import ModelResponse from '../../services/apiResponse/ModelResponse';
import DOMService from '../../services/DOMService';
import EntityManager from '../../services/EntityManager';

export interface IRecordManagerProps{
  parentModel?: Model
  searchOnUrl?: boolean
}


export interface IRecordManagerState<M extends Model>{
  models: M[]
  input: React.RefObject<HTMLInputElement>
  totalCount: number
  creating: boolean
  page: number
  lastPage: number
  total: number
  filter: {[attr: string]: any}
  search: string
  searching: boolean
}

export interface IInjectedRecordManagerProps<M extends Model> extends IRecordManagerState<M>{
  manager: any,
  defaultFilter?: any
}

export interface IRecordManagerOptions {
  paginate?: boolean
  loadOnReady?: boolean
  modelClass: any
}

const recordManager = <P extends IRecordManagerProps & IInjectedRecordManagerProps<Model>>(Component: React.ComponentType<P>, options: IRecordManagerOptions) => {

  return class extends React.Component<P & IInjectedRecordManagerProps<Model>, IRecordManagerState<Model>> {

    public lastSearch: string;
    public options

    constructor(props: any) {
      super(props)

      let urlState = {
        search: "" ,
        searching: false
      }
      // if (props.searchOnUrl) {
      //   let param: any = queryString.parse(this.props.location.search);
      //   urlState = {
      //     search: param?.search ,
      //     searching: !!param?.search
      //   }
      // }
  
      this.state = {
        models: null,
        input: React.createRef<HTMLInputElement>(),
        totalCount: null,
        creating: false,
        page: options.paginate ? 1 : null,
        lastPage: null,
        total: null,
        filter: props.defaultFilter || {},
        ...urlState
      }
    }

    componentDidMount() {
      if (options.loadOnReady) this.loadModels();
    }

    public loadModels = async () => {
      let params = this.buildParams();
      if (options.paginate) {
        let response = await EntityManager.allWithPaginate<Model>(options.modelClass, {params, parentModel: this.props.parentModel});
        this.setState({models: response.models, page: response.currentPage, lastPage: response.lastPage, total: response.total});
      } else {
        let response = await EntityManager.all<Model>(options.modelClass, {params, parentModel: this.props.parentModel});
        this.setState({models: response.models, totalCount: response.count});
      }
    }

    public buildParams() {
      let params = {...this.state.filter, page: this.state.page};
      if (this.state.search) params["search"] = this.state.search;
      Object.keys(params).forEach(key => {
        if (!params[key]) delete params[key];
      })
      return params;
    }

    public onPageChange = (page: number) => {
      this.setState({page}, () => this.loadModels());
    }

    public updateAll = async () => {
      await EntityManager.update(options.modelClass, {params: {models: this.state.models}});
      this.loadModels();
    }

    public create = async (model: Model): Promise<ModelResponse<Model>> => {
      this.setState({creating: true});
      let response = await EntityManager.create(model, {parentModel: this.props.parentModel});
      let createdModel = response.model;
      const newState = { creating: false, models: !this.state.models ? [createdModel] : [...this.state.models, createdModel] };
      this.setState(newState);
      return response;
    }

    public update = async (model: Model): Promise<ModelResponse<Model>> => {
      return await EntityManager.update(model, {toaster: true});
    }

    public updateOrCreate = async (model: Model): Promise<ModelResponse<Model>> => {
      if (model.id) return this.update(model);
      else          return this.create(model);
    }

    public delete = async (model: Model, afterDelete?) => {
      DOMService.alert({
        title: "Suppression",
        message: "Etes vous sûr de vouloir supprimer cet élément ?",
        onConfirm: async () => {
          await EntityManager.delete(model);
          DOMService.close();
          if (afterDelete) afterDelete();
          if (options.loadOnReady) this.loadModels();
        }
      });
    }

    handleSearch = (search) => {
      this.setState({search})
    }

    async onSearch() {
      if (!this.state.search && !this.lastSearch) return;
      // this.putSearchInUrl();
      this.lastSearch = this.state.search;
      this.setState({ searching: true }, () => this.loadModels());
    }

    onFilter = async (name: string, value) => {
      let filter = {[name]: value};
      this.setState({filter: {...this.state.filter, ...filter}}, () => this.loadModels());
    }

    clearSearch = () => {
      this.setState({ search: "" , searching: false}, () => {
        // this.putSearchInUrl();
        this.lastSearch = "";
        this.loadModels();
        this.state.input.current.focus();
      });
    }

    // putSearchInUrl() {
    //   if (!this.props.searchOnUrl) return;
    //   const search = "?" + queryString.stringify({ search: this.state.search }, {skipNull: true, skipEmptyString: true});
    //   this.props.history.push({
    //     search
    //   })
    // }

    public render() {
      return (
        <Component {...this.props as P} manager={this} {...this.state} />
      )
    }
  }
}


export default recordManager;

