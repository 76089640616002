import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminDashboardPage from './adminPage/AdminDashboardPage';
import AdminNewsPage from './adminPage/AdminNewsPage';
import AdminOrdersPage from './adminPage/AdminOrdersPage';
import AdminProductsPage from './adminPage/AdminProductsPage';
import AdminRewardsPage from './adminPage/AdminRewardsPage';
import OrdersList from './components/admin/orders/OrdersList';
import CustomerList from './components/customer/CustomerList';
import BottomNavbar from './components/layout/BottomNavbar';
import NoticeList from './components/notice/NoticeList';
import SessionService from './services/SessionService';
import { Redirect } from 'react-router';
import AdminSettingsPage from './adminPage/AdminSettingsPage';
import NotificationService from './services/NotificationService';
import OrderShowPage from './page/OrderShowPage';

export interface IAdminProps {
}

export interface IAdminState {
  initialized: boolean
}

export default class Admin extends React.Component<IAdminProps, IAdminState> {
  constructor(props: IAdminProps) {
    super(props);

    this.state = {
      initialized: false
    }
  }

  public render() {
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const firstRoutingElement = pathSegments[1];
    const isDigit = /^\d+$/.test(firstRoutingElement);
    if(!isDigit || !!!SessionService.user.shops.find((shop)=> {return shop.id == firstRoutingElement})){
      return <Redirect to={"/"+ SessionService.user.shops[0].id +"/charts"} />
    }

    if (SessionService.user.isDeliveryMan()) return <OrdersList id_boutique={firstRoutingElement} defaultFilter={{"shop_id": firstRoutingElement}}/>
    return (
      <>
        {/* <AdminNavbar/> */}
          <div className="admin-body">
            <Switch>
              <Route exact path="/"       component={() => <Redirect to="/1/charts"/>} />
              <Route path="/:id_boutique/news"       component={(router) => <AdminNewsPage id_boutique={router.match.params.id_boutique}/>} />
              <Route path="/:id_boutique/products"   render={(router) => <AdminProductsPage router={router} id_boutique={router.match.params.id_boutique}/>} />
              <Route exact path="/:id_boutique/charts/customers"  component={(router) => <CustomerList defaultFilter={{type: "customer"}} router={router} id_boutique={router.match.params.id_boutique}/>} />
              <Route exact path="/:id_boutique/charts/notices"  component={(router) => <NoticeList router={router} id_boutique={router.match.params.id_boutique} defaultFilter={{"with_order": true, "with_user": true, "shop_id": router.match.params.id_boutique}}/>} />
              <Route path="/:id_boutique/charts"    component={(router) => <AdminDashboardPage router={router} id_boutique={router.match.params.id_boutique}/>} />
              <Route path="/:id_boutique/orders"     component={(router) => <AdminOrdersPage id_boutique={router.match.params.id_boutique}/>} />
              <Route path="/:id_boutique/settings"     component={(router) => <AdminSettingsPage router={router} id_boutique={router.match.params.id_boutique}/>}/>
              <Route path="/:id_boutique/rewards"    component={(router) => <AdminRewardsPage id_boutique={router.match.params.id_boutique}/>} />
            </Switch>
          </div>
          <BottomNavbar/>
      </>
    );
  }
}

